<template>
  <div>
    <template v-if="loading">
      <div class="pa-9 mx-4">
        <v-container>
          <v-skeleton-loader
            type="card, card, article, date-picker"
          ></v-skeleton-loader>
        </v-container>
      </div>
    </template>
    <template v-else>
      <div class="claim-detail pa-10">
        <v-container>
          <v-row align="center">
            <v-col cols="12" class="mb-2">
              <h3 class="page-title font-weight-bold">クレーム詳細</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-row no-gutters align="center" class="mb-4">
                <v-col cols="auto">
                  <h3 class="task-id">
                    Task ID:
                    <span class="ml-2">
                      <router-link
                        :to="{
                          name: 'TaskView',
                          params: { id: this.claim.task_id }
                        }"
                        target="_blank"
                      >
                        {{ this.claim.task_id }}
                      </router-link>
                    </span>
                  </h3>
                </v-col>
                <v-col cols="auto" class="ml-8 date-blk">
                  {{ this.claimDate }}
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-4">
                <v-col class="company-name">
                  <router-link
                    :to="{
                      name: 'CustomerViewDetailBasic',
                      params: {
                        customer_id: this.claim.customer_id,
                        site_id: this.claim.site_id
                      }
                    }"
                    target="_blank"
                  >
                    {{ this.claim.customer.name }}
                  </router-link>
                  <span class="ml-4">{{ this.claim.site.name }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-4">
                <v-col>
                  <span class="service-id">
                    {{ this.claim.task.service.name }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div v-if="this.claim.claim_category_id">
                    <v-chip color="#8C5F5F" text-color="white" v-for="(item, index) in claimCategories" :key="`claim-category-${index}`" label small class="mr-3 mb-2">
                      {{item}}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row no-gutters>
                <v-col
                  cols="auto"
                  class="d-flex flex-column"
                  :class="{'ml-2': index !== 0 }"
                  v-for="(member, index) in taskMembers"
                  :key="`member-${index}`"
                >
                  <v-avatar size="36" :class="index === 0 ? 'leader' : ''">
                    <img class="avatar" :src="getProfileImageUrl(member)" />
                  </v-avatar>
                  <span class="member-name">{{ member.last_name }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider color="#BDBDBD" class="my-10"></v-divider>
          <v-row>
            <v-col cols="12" class="claim-content">
              <span class="mb-4 py-3 px-6 d-inline-block">クレーム内容</span>
              <p>{{ this.claim.description }}</p>
            </v-col>
            <v-col cols="12" class="claim-content">
              <span class="mb-4 py-3 px-6 d-inline-block">対応内容</span>
              <p>{{ this.claim.response }}</p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="create-task-footer px-6">
        <v-row class="justify-end">
          <v-col cols="auto">
            <v-btn
              :to="{
                name: 'ClaimList'
              }"
              text
              rounded
              min-width="150"
              class="mr-8"
              >一覧に戻る</v-btn
            >
          </v-col>

          <v-col cols="auto">
            <v-btn
              :to="{
                name: 'ClaimEdit',
                params: {
                  id: this.id
                }
              }"
              color="#4F55A7"
              class="white--text"
              min-width="150"
              rounded
              >編集する</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'Detail',
  components: {},
  props: ['id'],
  data() {
    return {
      root: process.env.VUE_APP_BASEURL,
      preview: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    claim() {
      return this.$store.getters.claimById
    },
    allCategories() {
      return this.$store.getters.allClaimCategories
    },
    claimCategories() {
      let categories = []
      this.allCategories.forEach(category => {
        if (this.claim.claim_category_id.includes(category.id)) {
          categories.push(category.name)
        }
      })
      return categories
    },
    claimDate() {
      return dayjs(this.claim.task.date).format(`YYYY年MM月DD日`)
    },
    taskMembers() {
      let members = this.claim.task.task_members
      let leaderId = this.claim.task.leader_id

      // Move the leader to the beginning of members array.
      if (leaderId) {
        return members?.sort((a, b) =>
          leaderId === a.id ? -1 : leaderId === b
        )
      }
      return members
    }
  },
  created() {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      this.$store.dispatch('CLAIM_GET_BY_ID', this.id).then(() => {
        this.loading = false
      })
    },
    getProfileImageUrl(user) {
      return user.profile_image
        ? user.profile_image_url
        : `${this.root}images/userblank.png`
    }
  }
}
</script>

<style src="./Detail.scss" lang="scss" scoped></style>
