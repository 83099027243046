var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? [_c('div', {
    staticClass: "pa-9 mx-4"
  }, [_c('v-container', [_c('v-skeleton-loader', {
    attrs: {
      "type": "card, card, article, date-picker"
    }
  })], 1)], 1)] : [_c('div', {
    staticClass: "claim-detail pa-10"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v("クレーム詳細")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-row', {
    staticClass: "mb-4",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "task-id"
  }, [_vm._v(" Task ID: "), _c('span', {
    staticClass: "ml-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'TaskView',
        params: {
          id: this.claim.task_id
        }
      },
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(this.claim.task_id) + " ")])], 1)])]), _c('v-col', {
    staticClass: "ml-8 date-blk",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(this.claimDate) + " ")])], 1), _c('v-row', {
    staticClass: "mb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "company-name"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'CustomerViewDetailBasic',
        params: {
          customer_id: this.claim.customer_id,
          site_id: this.claim.site_id
        }
      },
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(this.claim.customer.name) + " ")]), _c('span', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(this.claim.site.name))])], 1)], 1), _c('v-row', {
    staticClass: "mb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "service-id"
  }, [_vm._v(" " + _vm._s(this.claim.task.service.name) + " ")])])], 1), _c('v-row', [_c('v-col', [this.claim.claim_category_id ? _c('div', _vm._l(_vm.claimCategories, function (item, index) {
    return _c('v-chip', {
      key: "claim-category-".concat(index),
      staticClass: "mr-3 mb-2",
      attrs: {
        "color": "#8C5F5F",
        "text-color": "white",
        "label": "",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1) : _vm._e()])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.taskMembers, function (member, index) {
    return _c('v-col', {
      key: "member-".concat(index),
      staticClass: "d-flex flex-column",
      class: {
        'ml-2': index !== 0
      },
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      class: index === 0 ? 'leader' : '',
      attrs: {
        "size": "36"
      }
    }, [_c('img', {
      staticClass: "avatar",
      attrs: {
        "src": _vm.getProfileImageUrl(member)
      }
    })]), _c('span', {
      staticClass: "member-name"
    }, [_vm._v(_vm._s(member.last_name))])], 1);
  }), 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-10",
    attrs: {
      "color": "#BDBDBD"
    }
  }), _c('v-row', [_c('v-col', {
    staticClass: "claim-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "mb-4 py-3 px-6 d-inline-block"
  }, [_vm._v("クレーム内容")]), _c('p', [_vm._v(_vm._s(this.claim.description))])]), _c('v-col', {
    staticClass: "claim-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "mb-4 py-3 px-6 d-inline-block"
  }, [_vm._v("対応内容")]), _c('p', [_vm._v(_vm._s(this.claim.response))])])], 1)], 1)], 1), _c('div', {
    staticClass: "create-task-footer px-6"
  }, [_c('v-row', {
    staticClass: "justify-end"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-8",
    attrs: {
      "to": {
        name: 'ClaimList'
      },
      "text": "",
      "rounded": "",
      "min-width": "150"
    }
  }, [_vm._v("一覧に戻る")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "to": {
        name: 'ClaimEdit',
        params: {
          id: this.id
        }
      },
      "color": "#4F55A7",
      "min-width": "150",
      "rounded": ""
    }
  }, [_vm._v("編集する")])], 1)], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }